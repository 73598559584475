var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_make_file_page bbox d-flex align-center",
    },
    [
      0
        ? _c(
            "div",
            {
              staticClass: "wrapper d-flex align-center cp",
              on: {
                click: function ($event) {
                  return _vm.previewFile()
                },
              },
            },
            [
              _c("div", {
                staticClass: "icon flex-shrink-0",
                class: [_vm.$tools.getExtName(_vm.file.filename)],
              }),
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.file.filename)),
              ]),
              _vm.file.progress && !_vm.file.url
                ? _c("div", {
                    staticClass: "progress",
                    style: { width: `${_vm.file.progress || 0}%` },
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("widget-file-unit", {
        staticClass: "file_wrapper",
        attrs: { file: _vm.file, editable: _vm.edit, show_icon: false },
        on: { handleUpload: _vm.reUpload },
        nativeOn: {
          click: function ($event) {
            return _vm.previewFile.apply(null, arguments)
          },
        },
      }),
      _vm.edit
        ? _c("div", {
            staticClass: "delete button",
            on: {
              click: function ($event) {
                return _vm.deleteFile()
              },
            },
          })
        : _vm._e(),
      _vm.use && !_vm.edit
        ? _c("div", {
            staticClass: "button download",
            on: {
              click: function ($event) {
                return _vm.downloadFile()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }