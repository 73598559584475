var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_make_hyperlink_page bbox d-flex align-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "link_group bbox d-flex flex-column",
          class: { edit: _vm.item.edit, cp: _vm.use },
          on: {
            click: function ($event) {
              return _vm.goToUrl()
            },
          },
        },
        [
          _c("div", { staticClass: "key_group bbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.key,
                  expression: "key",
                },
              ],
              staticClass: "key",
              class: { cp: _vm.use },
              attrs: {
                type: "text",
                placeholder: "链接说明",
                readonly: !_vm.item.edit,
              },
              domProps: { value: _vm.key },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.key = $event.target.value
                },
              },
            }),
            !_vm.item.edit && _vm.edit
              ? _c("div", { staticClass: "key_fun bbox d-flex align-center" }, [
                  _c("div", {
                    staticClass: "button cp edit",
                    on: {
                      click: function ($event) {
                        return _vm.doEdit()
                      },
                    },
                  }),
                  _c("div", {
                    staticClass: "button cp delete",
                    on: {
                      click: function ($event) {
                        return _vm.doDelete()
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          !_vm.sell
            ? _c("div", { staticClass: "val_group bbox" }, [
                _vm.use && !_vm.edit
                  ? _c("div", { staticClass: "val show" }, [
                      _vm._v(_vm._s(_vm.val)),
                    ])
                  : _vm._e(),
                _vm.preview
                  ? _c("div", { staticClass: "val" }, [
                      _vm._v("购买查看链接地址"),
                    ])
                  : _vm._e(),
                _vm.edit
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.val,
                          expression: "val",
                        },
                      ],
                      staticClass: "val input bbox",
                      attrs: {
                        type: "text",
                        placeholder: "链接地址",
                        readonly: !_vm.item.edit,
                      },
                      domProps: { value: _vm.val },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.doEnter($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.val = $event.target.value
                        },
                      },
                    })
                  : _vm._e(),
                _vm.item.edit
                  ? _c("div", {
                      staticClass: "done cp",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.editHyperlink()
                        },
                      },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }