var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_make_video_part_page bbox d-flex flex-column align-start justify-space-between",
    },
    [
      _c("input", {
        ref: "file",
        staticClass: "input_video",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "video/*", multiple: "" },
        on: {
          change: function ($event) {
            return _vm.fileChange()
          },
        },
      }),
      _vm._l(_vm.list, function (item) {
        return [
          !item.delete
            ? _c("video-unit", {
                key: item.id || item.custom_index,
                attrs: {
                  item: item,
                  edit: _vm.edit,
                  use: _vm.use,
                  preview: _vm.preview,
                },
                on: {
                  handleDelete: _vm.deleteFile,
                  handleReUpload: _vm.reUpload,
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }