var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_make_hyperlink_part_page bbox d-flex flex-column align-start justify-space-between",
    },
    [
      _vm.switch_edit
        ? _c(
            "div",
            { staticClass: "edit_group bbox d-flex flex-column edit" },
            [
              _c("div", { staticClass: "key_group bbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.key,
                      expression: "key",
                    },
                  ],
                  staticClass: "key",
                  attrs: { type: "text", placeholder: "链接说明" },
                  domProps: { value: _vm.key },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.key = $event.target.value
                    },
                  },
                }),
                0
                  ? _c(
                      "div",
                      { staticClass: "key_fun bbox d-flex align-center" },
                      [
                        _c("div", { staticClass: "button cp edit" }),
                        _c("div", { staticClass: "button cp delete" }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "val_group bbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.val,
                      expression: "val",
                    },
                  ],
                  staticClass: "val",
                  attrs: { type: "text", placeholder: "链接地址" },
                  domProps: { value: _vm.val },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.doEnter($event)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.val = $event.target.value
                    },
                  },
                }),
                _c("div", {
                  staticClass: "done cp",
                  on: {
                    click: function ($event) {
                      return _vm.addHyperlink()
                    },
                  },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _vm._l(_vm.list, function (item) {
        return [
          !item.delete
            ? _c("hyperlink", {
                key: item.id || item.custom_index,
                attrs: {
                  item: item,
                  edit: _vm.edit,
                  sell: _vm.sell,
                  use: _vm.use,
                  preview: _vm.preview,
                },
                on: {
                  handleEdit: _vm.editHyperlink,
                  handleDelete: _vm.handleDelete,
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }