<template>
  <div
    class="components_task_side_bar_teach_pages_make_hyperlink_page bbox d-flex  align-center"
  >
    <div class="link_group bbox d-flex flex-column"
      :class="{edit:item.edit, cp:use}"
      @click="goToUrl();"
    >
      <div class="key_group bbox">
        <input type="text" class="key"
          :class="{ cp:use}"
          placeholder="链接说明"
          :readonly="!item.edit"
          v-model="key"
        >
        <div class="key_fun bbox d-flex align-center" v-if="!item.edit && edit">
          <div class="button cp edit"
            @click="doEdit();"
          ></div>
          <div class="button cp delete"
            @click="doDelete();"
          ></div>
        </div>
      </div>
      <div class="val_group bbox"
        v-if="!sell"
      >
        <div class="val show"
          v-if="use && !edit"
        >{{val}}</div>
        <div class="val"
          v-if="preview"
        >购买查看链接地址</div>
        <input type="text" class="val input bbox"
          placeholder="链接地址"
          :readonly="!item.edit"
          v-model="val"
          v-if="edit"
          @keydown.enter="doEnter($event);"
          
        >
        <div class="done cp"
          v-if="item.edit"
          @click.stop="editHyperlink()"
        ></div>
      </div>
    </div>

  </div>
</template>
<script>
import global from '@/api/global'
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    use: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    sell: {
      type: Boolean,
      default: false,
    },
    
  },
  data(){
    return {
      key: '',
      val: '',
    }
  },
  mounted(){
    // console.log(this.sell)
    this.init();
    //  // console.log(this.use);
  },
  watch: {
    'item.content': {
      handler: function() {
        // console.log(val);
        this.init()
      },
      deep: true,
    }
  },
  methods: {
    init(){
      const {item} = this;
      const {content} = item;
      const arr = content.split('|');
      this.key = arr[0];
      this.val = arr[1];
    },
    chineseHandler(e){
      let status = false;
      if(e.keyCode === 229){
        status = true;
      }
      return status;
    },
    doEnter(e){
      const is_chinese = this.chineseHandler(e);
      if(is_chinese){
        return false;
      }
      this.editHyperlink();
    },
    doEdit(){
      this.$set(this.item, 'edit', true);
      
    },
    editHyperlink(){
      const {val, key} = this;
      if(val.trim() === '' && key.trim() === ''){
        return;
      }
      const data = Object.assign(
        {},
        this.item,
        {content: `${key.trim()}|${val.trim()}`, edit: false},
      )
      this.$emit('handleEdit', {edit:data,item:this.item});
      
    },
    doDelete(){
      const {item} = this;
      this.$emit('handleDelete', item);
    },
    async goToUrl() {
      const { use, preview, val, edit } = this
      if (!use) {
        return
      }
      if (edit) {
        return
      }
      if (preview) {
        this.$notice({desc: '请先购买教学内容'});
        return
      }
      if (val === '') {
        return
      }
      let link = ''
      if (val.indexOf('smzyk.36ve.com') !== -1) {
        const res = await global.doPost({
          url: '/projectTeach/getresourcesurl',
          data: {
            url: val
          }
        })
        if (res.message === 'success') {
          const { contents } = res
          const { returnurl } = contents
          if (returnurl) {
            window.open(returnurl, '_blank')
          }
        }
        return
      }
      if (val.indexOf('http://') === -1 && val.indexOf('https://') === -1) {
        link = `http://${val}`
      }else{
        link = val
      }
      // window.open(link, '_blank')
      const linkDom = document.createElement('a')
      linkDom.href = link
      linkDom.target = '_blank'
      document.body.appendChild(linkDom)
      linkDom.click()
      setTimeout(() => {
        document.body.removeChild(linkDom)
      }, 30000)
    },
    // deleteFile(){
    //   const {item} = this
    //   this.$emit('handleDelete', item);
    // }

  },
}
</script>
<style lang="scss" scoped>

.components_task_side_bar_teach_pages_make_hyperlink_page{
  @include transition;
  width: 100%;
  z-index: 2;
  padding-top: 10px;
  .link_group{
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    padding: 0 10px;
    border: 1px solid transparent;
    &:hover{
      background-color: #F6F6F6;
      // border: 1px solid #F6F6F6;
    }
    input{
      @include placeholder(#ccc);
      background-color: transparent;
      outline: none;
      border: none;
      width: 100%;
      line-height: 30px;
      color:#333;
      font-size: 14px;
    }
    .val{
      font-size: 14px;
      line-height: 30px;
      color: #333;
    }
    &.edit{
      border: 1px solid #E5E5E5;
      .key_group{
        border-bottom: 1px solid #f4f4f4;
      }
    }
    &:hover{
      .key_group .key_fun{
        opacity: 1;
      }
    }
    .key_group{
      width: 100%;
      height: 30px;
      position: relative;
      z-index: 2;
     
      .key_fun{
        opacity: 0;
        position: absolute;
        z-index: 3;
        top:50%;
        right: 0;
        transform: translateY(-50%);
        .button{
          width: 15px;
          height: 15px;
          margin-left: 8px;
          &:first-child{
            margin-left: 0;
          }
          &.edit{
            background-image: url(~@/assets/images/teach/edit.png);
          }
          &.delete{
            background-image: url(~@/assets/images/teach/delete.png);
          }
        }
      }
    }
    .val_group{
      width: 100%;
      min-height: 30px;
      position: relative;
      z-index: 2;
      .done{
        position: absolute;
        z-index: 3;
        width: 22px;
        height: 22px;
        background-image: url(~@/assets/images/teach/hyperlink_done.png);
        top:50%;
        right: 0;
        transform: translateY(-50%);
      }
      .input{
        padding-right: 30px;
      }
      .val.show{
        word-break: break-all;
      }
    }
  }
  
}
</style>