<template>
  <div
    class="components_task_side_bar_teach_pages_make_hyperlink_part_page bbox d-flex flex-column align-start justify-space-between"
  >
    <div class="edit_group bbox d-flex flex-column edit"
      v-if="switch_edit"
    >
      <div class="key_group bbox">
        <input type="text" class="key"
          placeholder="链接说明"
          v-model="key"
        >
        <div class="key_fun bbox d-flex align-center" v-if="0">
          <div class="button cp edit"></div>
          <div class="button cp delete"></div>
        </div>
      </div>
      <div class="val_group bbox">
        <input type="text" class="val"
          placeholder="链接地址"
          v-model="val"
          @keydown.enter="doEnter($event)"
        >
        <div class="done cp"
          @click="addHyperlink()"
        ></div>
      </div>
    </div>
    <template
      v-for="item in list"
    >
      <hyperlink
        v-if="!item.delete"
        :key="item.id || item.custom_index"
        :item="item"
        @handleEdit="editHyperlink"
        @handleDelete="handleDelete"
        :edit="edit"
        :sell="sell"
        :use="use"
        :preview="preview"
      ></hyperlink>
    </template>
  </div>
</template>
<script>
import Hyperlink from './Hyperlink.vue';
export default {
  components: {
    Hyperlink,
  },
  props:{
    list: {
      type: Array,
      default: function(){
        return [];
      }
    },
    edit: {
      type: Boolean,
      default: false,
    },
    use: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    sell: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      custom_index: 0,
      switch_edit: false,
      key: '',
      val: '',
    }
  },
  methods:{
    touchAdd(){
      this.switch_edit = true;
      this.key = '';
      this.val = '';
    },
    addHyperlink(){
      const {val, key} = this;
      if(val.trim() == '' && key.trom() == ''){
        return;
      }
      let data = {
        content: `${key.trim()}|${val.trim()}`,
        type:3,
        custom_index: this.custom_index++,
      }
      this.$emit('handleAdd', data);
      this.switch_edit = false;
    },
    editHyperlink(data){
      this.$emit('handleEdit', data);
    },
    handleDelete(item){
      this.$set(item, 'delete', true);
      // this.$emit('handleDelete', item);
    },
    chineseHandler(e){
      let status = false;
      if(e.keyCode == 229){
        status = true;
      }
      return status;
    },
    doEnter(e){
      const is_chinese = this.chineseHandler(e);
      if(is_chinese){
        return false;
      }
      this.addHyperlink();
    },
  }
}
</script>

<style lang="scss" scoped>
.components_task_side_bar_teach_pages_make_hyperlink_part_page{
  .edit_group{
    width: 100%;
    background: #F6F6F6;
    border-radius: 2px;
    padding: 0 10px;
    border: 1px solid #F6F6F6;
    input{
      @include placeholder(#ccc);
      background-color: transparent;
      outline: none;
      border: none;
      width: 100%;
      line-height: 30px;
      color:#333;
      font-size: 14px;
    }
    &.edit{
      border: 1px solid #E5E5E5;
      .key_group{
        border-bottom: 1px solid #f4f4f4;
      }
    }
    .key_group{
      width: 100%;
      height: 30px;
      position: relative;
      z-index: 2;
      .key_fun{
        position: absolute;
        z-index: 3;
        top:50%;
        right: 0;
        transform: translateY(-50%);
        .button{
          width: 15px;
          height: 15px;
          margin-left: 8px;
          &:first-child{
            margin-left: 0;
          }
          &.edit{
            background-image: url(~@/assets/images/teach/edit.png);
          }
          &.delete{
            background-image: url(~@/assets/images/teach/delete.png);
          }
        }
      }
    }
    .val_group{
      width: 100%;
      height: 30px;
      position: relative;
      z-index: 2;
      .done{
        position: absolute;
        z-index: 3;
        width: 22px;
        height: 22px;
        background-image: url(~@/assets/images/teach/hyperlink_done.png);
        top:50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
