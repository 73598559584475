var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_make_fun_page bbox justify-space-between d-flex align-center",
    },
    [
      _c("div", { staticClass: "title_group d-flex align-center" }, [
        _c("div", { staticClass: "icon", class: [_vm.type] }),
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
      _vm.edit && _vm.open_add
        ? _c("div", { staticClass: "button", on: { click: _vm.doClick } })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }