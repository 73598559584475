<template>
  <div
    class="components_task_side_bar_teach_pages_make_file_part_page bbox d-flex flex-column align-start justify-space-between"
  >
    <!--预览图片 -->
    <el-image
      ref="image"
      v-show="0"
      :src="preview_img_src"
      :preview-src-list="img_list"
      @click.native="setImgZindex();$api.stopPropagation($event)"
    ></el-image>
      <!-- :accept="$input_file_accept" -->
    <input
      type="file"
      ref="file"
      class="input_video"
      style="display:none;"
      @change="fileChange()"
      multiple
    >
    <template
      v-for="item in list"
    >
      <file
        v-if="!item.delete"
        :key="item.id || item.custom_index"
        :item="item"
        :edit="edit"
        :use="use"
        :preview="preview"
        @handlePreview="previewFile"
        @handleDelete="deleteFile"
        @handleReUpload="reUpload"
      ></file>
    </template>
  </div>
</template>
<script>
import File from './File.vue';
export default {
  components: {
    File,
  },
  props: {
    list: {
      type: Array,
      default: function(){
        return [];
      },
    },
    uploading:{
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    use: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      custom_index: 0,
      img_list: [],
      preview_img_src: '',
    }
  },
  methods: {
    touchAdd(){
      if(this.uploading){
        this.$notice({desc: '有文件正在上传, 请稍候再试!'});
        return;
      }
      this.$refs.file.click();
    },
    fileChange(){
      const files = this.$refs.file.files;
      let upload_list = [];
      // 最先加的放在最下面
      for(let i = 0; i<files.length; i ++){
        const file = files[i];
        const filedata = {
          filename: file.name,
          filesize: parseInt(Math.ceil(file.size / 1024)), // 向上取整
          filetype: this.$tools.getRealExtName(file.name),
          file,
        }
        if(filedata.filesize >= 1000*1024){
          this.$notice({desc: '无法上传超过1G的文件'});
        }else{
          upload_list.unshift({file:filedata, type:2,custom_index: this.custom_index++,});
        }
      }
      this.$emit('handleAdd', upload_list);
      this.$refs.file.value = ""; // 清空图片列表
    },
    setImgZindex(){
      const zIndex = this.$utils.getZIndex();
      document.querySelectorAll('.el-image-viewer__wrapper').forEach(item=>{
        item.style.zIndex = zIndex;
      })
    },
    previewFile(item){
      // console.log(item, item.file)
      const {file} = item;
      const {filename,url} = file;
      const filetype = this.$tools.getExtName(filename);
      if(filetype === 'mp4'){
        this.previewVideo(url);
      }
      if(filetype === 'pic' || filetype === 'jpg' || filetype === 'jpeg' || filetype === 'png'){
        this.previewPicture(file);
      }
      if(filetype === 'doc' || filetype === 'ppt' || filetype === 'xls' || filetype === 'docx' || filetype === 'pptx' || filetype === 'xlsx'){
        this.previewOffice(url);
      }
      if(filetype === 'pdf'){
        this.previewPdf(url);
      }
    },
    previewVideo(url){
      let protocol = location.protocol;
      let src = this.$file_url;
      // 根据当前环境切换http/https源
      if(protocol == 'http:'){
        src = this.$file_url_http;
      }
      src += url;
      this.$openVideo({src})
    },
    previewPicture(file){
      const {url, createTime} = file;
      // console.log(url, this.list);
      const {list} = this;
      // const {fileList} = item;
      let img_list = [];
      // const critical = this.$moment('2021-09-08 10:56').format('x'); // 临界时间 // 以发布日期为准
      // const critical = this.$critical;
      // const picture_critical = this.$picture_critical;
      list.forEach(unit=>{
        if(this.$tools.getExtName(unit.file.filename) === 'pic' && unit.file.url){
          img_list.push(`${this.$file_url}${unit.file.url}`);
        }
      })
      this.$set(this,'img_list', img_list);
      this.preview_img_src = `${this.$file_url}${url}`;
      this.$nextTick(function(){
        // // console.log(this.$refs.image.$el)
        this.$refs.image.clickHandler();
        this.setImgZindex();
      })
    },
    previewOffice(url){
      const basic_url = 'https://view.officeapps.live.com/op/view.aspx?src=';
      const previewUrl = `${basic_url}${this.$file_url}${url}`
      // console.log(
      //   previewUrl,
      //   `${basic_url}${this.$file_url}${url}`
      // )
      // return
      window.open(previewUrl, '_blank')
    },
    previewPdf(url){
      window.open(`${this.$file_url}${url}`)
    },
    deleteFile(item){
      this.$set(item, 'delete', true);
    },
    reUpload(item){
      this.$emit('handleReUpload', {type: 'fileList', item});
    }

  }
}
</script>

<style lang="scss" scoped>
.components_task_side_bar_teach_pages_make_file_part_page{
  width: 100%;
}
</style>
