<template>
  <div
    class="components_task_side_bar_teach_pages_make_video_part_page bbox d-flex flex-column align-start justify-space-between"
  >
    <input
      type="file"
      ref="file"
      class="input_video"
      style="display:none;"
      accept="video/*"
      @change="fileChange()"
      multiple
    >
    <template
      v-for="item in list"
    >
      <video-unit
        v-if="!item.delete"
        :key="item.id || item.custom_index"
        :item="item"
        :edit="edit"
        :use="use"
        :preview="preview"
        @handleDelete="deleteFile"
        @handleReUpload="reUpload"
      ></video-unit>
     
    </template>
  </div>
</template>
<script>

import VideoUnit from './Video.vue';
export default {
  components: {
    VideoUnit,
  },
  props: {
    list: {
      type: Array,
      default: function(){
        return [];
      },
    },
    uploading:{
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    use: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      custom_index: 0,
    }
  },
  methods: {
    touchAdd(){
      if(this.uploading){
        this.$notice({desc: '有文件正在上传, 请稍候再试!'});
        return;
      }
      this.$refs.file.click();
    },
    fileChange(){
      // console.log(this.$refs.file.files);
      const files = this.$refs.file.files;
      let upload_list = [];
      // 最先加的放在最下面
      for(let i = 0; i<files.length; i ++){
        const file = files[i];
        const filedata = {
          filename: file.name,
          filesize: parseInt(Math.ceil(file.size / 1024)), // 向上取整
          filetype: this.$tools.getRealExtName(file.name),
          file,
        }
        if(filedata.filesize >= 1000*1024){
          this.$notice({desc: '无法上传超过1G的文件'});
        }else{
          upload_list.unshift({file:filedata, type:1,custom_index: this.custom_index++,});
        }
      }
      this.$emit('handleAdd', upload_list);
      this.$refs.file.value = ""; // 清空图片列表
    },
    deleteFile(item){
      this.$set(item, 'delete', true);
    },
    reUpload(item){
      this.$emit('handleReUpload', {type: 'videoList', item});
    }

  }
}
</script>

<style lang="scss" scoped>
.components_task_side_bar_teach_pages_make_video_part_page{
  width: 100%;
}
</style>
