var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_task_side_bar_teach_pages_make_file_part_page bbox d-flex flex-column align-start justify-space-between",
    },
    [
      _c("el-image", {
        directives: [
          { name: "show", rawName: "v-show", value: 0, expression: "0" },
        ],
        ref: "image",
        attrs: { src: _vm.preview_img_src, "preview-src-list": _vm.img_list },
        nativeOn: {
          click: function ($event) {
            _vm.setImgZindex()
            _vm.$api.stopPropagation($event)
          },
        },
      }),
      _c("input", {
        ref: "file",
        staticClass: "input_video",
        staticStyle: { display: "none" },
        attrs: { type: "file", multiple: "" },
        on: {
          change: function ($event) {
            return _vm.fileChange()
          },
        },
      }),
      _vm._l(_vm.list, function (item) {
        return [
          !item.delete
            ? _c("file", {
                key: item.id || item.custom_index,
                attrs: {
                  item: item,
                  edit: _vm.edit,
                  use: _vm.use,
                  preview: _vm.preview,
                },
                on: {
                  handlePreview: _vm.previewFile,
                  handleDelete: _vm.deleteFile,
                  handleReUpload: _vm.reUpload,
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }